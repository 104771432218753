import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <div>
        <SEO title="About" />
        <h1>About Us</h1>
        <p>
          lorem ipsum dolor sit amet, consectetur adipis lorem ipsum dolor sit
          amet, consectetur adipis lorem ipsum dolor sit amet, consectetur
          adipis lorem ipsum dolor sit amet, consectetur adipis lorem ipsum
          dolor sit amet, consectetur adipis lorem ipsum dolor sit amet,
          consectetur adipis
        </p>
      </div>
    </Layout>
  )
}

export default AboutPage
